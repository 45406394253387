import { render, staticRenderFns } from "./companies-viewer.html?vue&type=template&id=9379338c&scoped=true&"
import script from "./companies-viewer.vue?vue&type=script&lang=ts&"
export * from "./companies-viewer.vue?vue&type=script&lang=ts&"
import style0 from "./companies-viewer.scss?vue&type=style&index=0&id=9379338c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9379338c",
  null
  
)

export default component.exports