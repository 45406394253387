








import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class IconEwMap extends Vue {
  @Prop({type: Boolean, default: false}) fill: boolean;

  public get fillColor(): string {
    return this.fill ? '#02ACEA' : '#001B24';
  }
}
