var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"promo-page promo-companies"},[(!_vm.planIsLoading)?_c('search-bar',{class:{'invisible': _vm.isPromoPageNavigationInvisible},attrs:{"is-filter-search-disabled":_vm.isFilterSearchDisabled},on:{"search-action":_vm.onSearchClick,"filter-action":_vm.onTagsFilterClick}},[_vm._t("default",[_c('segment-control',{staticClass:"view-type-tabs"},_vm._l((_vm.companyListTypeTabs),function(tab,index){return _c('div',{key:'tab_' + index,staticClass:"segment-control text-nowrap",class:{ 'active': tab.isActive },on:{"click":function($event){return _vm.onCompanyListTypeActiveTabUpdate(tab, index)}}},[(index === 1)?_c('icon-ew-star',{staticClass:"icon-before",attrs:{"fill":!tab.isActive}}):_vm._e(),_c('span',[_vm._v(_vm._s(tab.title.trim()))])],1)}),0)],{"slot":"tabs"}),(_vm.$route.name === 'promo-page-events-companies')?_vm._t("default",[_c('div',{staticClass:"pavilion-filter",class:( _obj = {}, _obj['pavilion-filter-' + _vm.$i18n.locale] = true, _obj )},[(_vm.pavilions && _vm.pavilions.List.length)?[_c('mu-select',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              placement: 'bottom',
              content: _vm.$t('navigation.allHalls'),
              classes: ['vue-tooltip-dark'],
            }),expression:"{\n              placement: 'bottom',\n              content: $t('navigation.allHalls'),\n              classes: ['vue-tooltip-dark'],\n            }"}],staticClass:"pavilion-select",attrs:{"placeholder":_vm.$t('contacts.popups.createCompany.pavilions'),"full-width":"","solo":"","color":'#00b6f8'},on:{"change":_vm.onPavilionChange},model:{value:(_vm.selectedPavilionId),callback:function ($$v) {_vm.selectedPavilionId=$$v},expression:"selectedPavilionId"}},[_c('mu-option',{attrs:{"label":_vm.$t('navigation.allHalls'),"value":null,"selected":_vm.selectedPavilionId === null}},[_vm._v(" "+_vm._s(_vm.$t('navigation.all'))+" ")]),_vm._l((_vm.pavilions.List),function(pavilion,index){return _c('mu-option',{key:index,attrs:{"label":pavilion.name,"value":pavilion.id,"selected":_vm.selectedPavilionId === pavilion.id}},[_vm._v(" "+_vm._s(pavilion.name)+" ")])})],2)]:_vm._e()],2)],{"slot":"pavilions"}):_vm._e(),_vm._t("default",[_c('page-title',{attrs:{"route-name":"promo-page-events-companies","fallback-title":'' + _vm.$t('sideBar.hall')}}),_c('edit-entity-button',{attrs:{"target-route-name":"promo-page-cabinet-exponents"}})],{"slot":"title"}),(_vm.isPlanJson || (_vm.event && _vm.event.map_image))?_vm._t("default",[_c('segment-control',{staticClass:"view-type-tabs"},_vm._l((_vm.companyViewTypeTabs),function(tab,index){return _c('div',{key:'tab_' + index,staticClass:"segment-control",class:{ 'active': tab.isActive },on:{"click":function($event){return _vm.onCompanyViewTypeActiveTabUpdate(tab, index)}}},[(index === 0)?_c('icon-ew-list',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: _vm.$t('tooltip.companiesViewTypeList'),
              classes: ['vue-tooltip-dark'],
              placement: 'bottom',
              delay: 0,
            }),expression:"{\n              content: $t('tooltip.companiesViewTypeList'),\n              classes: ['vue-tooltip-dark'],\n              placement: 'bottom',\n              delay: 0,\n            }"}],staticClass:"icon-before",attrs:{"fill":!tab.isActive}}):_vm._e(),(index === 1)?_c('icon-ew-map',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: _vm.$t('tooltip.companiesViewTypeMap'),
              classes: ['vue-tooltip-dark'],
              placement: 'bottom',
              delay: 0,
            }),expression:"{\n              content: $t('tooltip.companiesViewTypeMap'),\n              classes: ['vue-tooltip-dark'],\n              placement: 'bottom',\n              delay: 0,\n            }"}],staticClass:"icon-before",attrs:{"fill":!tab.isActive}}):_vm._e()],1)}),0)],{"slot":"rightTabs"}):_vm._e()],2):_vm._e(),_c('div',{staticClass:"promo-page-content",class:{'promo-page-content-has-overlay': _vm.isCompanyMediaBlockOverlayVisible}},[(_vm.isCurrentFiltersLineVisible)?_c('div',{staticClass:"current-filters"},[_c('span',{ref:"currentFiltersTitle",staticClass:"title",attrs:{"data-after":":"}},[_vm._v(" "+_vm._s(_vm.$t('expoHallCurrentFilters.title'))+" ")]),_c('span',{ref:"currentFiltersList",staticClass:"list"},_vm._l((_vm.filterIds),function(tagId){return _c('span',{staticClass:"item"},[_vm._v(" "+_vm._s(((_vm.eventTags.find(function (tag) { return tag.id === tagId; }) || {}).name || ''))+" ")])}),0),_c('div',{ref:"currentFiltersActions",staticClass:"actions"},[_c('ew-button',{attrs:{"button-type":"tertiary","title":_vm.$t('button.change')},on:{"ew-button-click":_vm.onTagsFilterClick}}),_c('ew-button',{attrs:{"button-type":"tertiary","title":_vm.$t('button.reset')},on:{"ew-button-click":_vm.onResetCurrentFiltersClick}})],1)]):_vm._e(),_c('router-view')],1),(_vm.showCompanyFilters)?_c('div',{staticClass:"category-chooser-dimmer"}):_vm._e(),(_vm.isCompanyCategoriesChooserVisible)?_c('chooseCompanyCategories',{ref:"chooseCompanyCategories",attrs:{"show":_vm.showCompanyFilters,"allTags":_vm.eventTags,"mode":"filter","selectedTagIds":_vm.selectedTags},on:{"tagListUpdated":_vm.filterTags,"close":function($event){_vm.showCompanyFilters = false},"resetSelectedTags":_vm.resetSelectedTags}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }